import * as React from "react"
import Layout from "../components/layout"


const IndexPage = ({data}) => {
  return (
    <Layout>
      <h1 className="text-3xl font-bold underline">
        Hello world!
      </h1>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <title>Home Page</title>
